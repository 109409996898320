import React from "react";
import Layout, { BaseScreenProps } from "./Layout";
import useApolloQuery from "../hooks/apollo/useApolloQuery";
import gql from "graphql-tag";
import { SnippetCollection } from "../data/interfaces";
import styled from "@emotion/styled";
import SnippetGroup from "../components/snippets/SnippetGroup";
import { Link } from "@reach/router";

export default function CollectionScreen({ username, collectionName }: CollectionScreenProps) {
  const { error, loading, collection } = useCollection(username, collectionName);
  return (
    <Layout>
      <StyledContainer>
        {loading && <div>Loading...</div>}
        {collection && !loading && (
          <SnippetGroup
            title={<Link to={"/" + collection.path}>{collection.name}/</Link>}
            snippets={collection.snippets}
            key={collection.path}
          />
        )}
      </StyledContainer>
    </Layout>
  );
}

export interface CollectionScreenProps extends BaseScreenProps {
  collectionName?: string;
  username?: string;
}
const useCollection = function(username, collectionName) {
  let { data, error, loading } = useApolloQuery(getQuery(username, collectionName));

  return {
    collection: data.collection as SnippetCollection,
    error,
    loading,
  };
};

const StyledContainer = styled.div`
  padding: 25px;
`;

const getQuery = function(username, collectionName) {
  return gql`
    query GetCollection {
      collection(user: "${username}", name: "${collectionName}") {
        name
        path
        snippets {
          title
          key
          filename
          path
          language {
            name
            key
          }
          description
          tags {
            key
          }
        }
      }
    }
  `;
};
