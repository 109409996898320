import React, { useCallback } from "react";
import { Link } from "@reach/router";
import styled from "@emotion/styled";
import Tags from "./Tags";
import { CodeSnippet } from "../../data/interfaces";
import { CurrentUser, useCurrentUserContext } from "../../hooks/useCurrentUser";
import { StyledCard } from "../primitives/Cards";

function SnippetCard({ snippet }: SnippetCardProps) {
  let { user: currentUser } = useCurrentUserContext();
  return (
    <StyledSnippetCard className="snippet-card" key={snippet.key}>
      <Link title="View snippet" to={"/" + snippet.path} className="title" rel="prerender">
        {snippet.title}
      </Link>
      <div>
        <SnippetPath currentUser={currentUser} snippet={snippet} />
        <Tags tags={snippet.tags} />
        <p>{snippet.description}</p>
        <StyledLanguageDisplay>
          <Link
            title={`${snippet.language.name} snippets`}
            to={`/explore/languages/${snippet.language.key}`}
          >
            {snippet.language.name}
          </Link>
        </StyledLanguageDisplay>
      </div>
    </StyledSnippetCard>
  );
}
export default React.memo(SnippetCard);

function SnippetPath({ snippet, currentUser }: SnippetPathProps) {
  let path = snippet.path.replace(location.pathname.substr(1).toLowerCase() + "/", "");
  let [filename, collection, author] = snippet.path.split("/").reverse();
  return (
    <div className="path monospace" title={snippet.path}>
      {author && (
        <React.Fragment>
          <Link className="author-link" to={"/" + author}>
            {author}
          </Link>
          /
        </React.Fragment>
      )}
      {collection}/{filename}
    </div>
  );
}

interface SnippetPathProps {
  currentUser: CurrentUser;
  snippet: CodeSnippet;
}

const StyledSnippetCard = styled(StyledCard)`
  /* padding-bottom: 20px; */
  .path {
    color: #999;
    font-size: 1rem;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 200px; */

    /* Reverse elipses */
    /* direction: rtl;
    text-align: left; */
    .author-link {
      font-weight: bold;
      /* color: var(--primary); */
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
  .tags {
    margin: 5px 0 20px 0;
    font-size: 0.9rem;
  }
  p {
    font-size: 0.8rem;
  }
`;
export interface SnippetCardProps {
  // Props
  snippet: CodeSnippet;
}

const StyledLanguageDisplay = styled.div`
  position: absolute;
  bottom: 8px;
  right: 5px;
  z-index: 1;
  a {
    color: var(--primary);

    padding: 5px;
    font-family: var(--monospace);
    /* text-transform: uppercase; */
    font-size: 0.8em;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
`;
