import React from "react";
import styled from "@emotion/styled";
import { lighten, darken, transparentize } from "polished";
import Breadcrumb from "./Breadcrumb";

const StyledBar = styled.div`
  padding: 10px 10px;
  background: ${(props) => transparentize(0.3, props.theme.dark)};
  display: flex;
  justify-content: space-between;
  min-height: 20px;
  .actions {
    display: flex;
    align-items: center;
    > * {
      margin-left: 10px;
    }
  }
`;
export default function CommandBar({ actions = null }) {
  return (
    <StyledBar>
      <Breadcrumb />
      {actions && <div className="actions">{actions}</div>}
    </StyledBar>
  );
}

export interface CommandBarProps {
  actions: JSX.Element;
}
