import React from "react";
import Layout, { BaseScreenProps } from "./Layout";

function HowToScreen({ location }: BaseScreenProps) {
  return (
    <Layout title="How to" hideCommandBar={true}>
      <>
        <div>Instructions on the Web App, CLI, and VS Code Extension will go here.</div>
      </>
    </Layout>
  );
}

export default HowToScreen;
