import React, { useMemo } from "react";
import Layout, { BaseScreenProps, StyledResultsLayout, StyledResults } from "./Layout";
import { filterCollections } from "../data/snippetsData";
import useSnippetFilter from "../hooks/useSnippetFilter";
import useUserSnippets from "../hooks/useUserSnippets";
import { orderBy } from "lodash";
import FilterPanel from "../components/snippets/FilterPanel";
import { Link } from "@reach/router";
import SnippetGroup from "../components/snippets/SnippetGroup";

export default function UserScreen({ username = "" }: UserScreenProps) {
  let { collections, filter, updateFilter, refiners } = useFilterableUserSnippets(
    username.toLowerCase()
  );

  if (!username) return <div>No username</div>;
  return (
    <Layout>
      <StyledResultsLayout>
        <FilterPanel
          filter={filter}
          updateFilter={updateFilter}
          excludes={["user"]}
          refiners={refiners}
        />
        <StyledResults>
          {collections
            .filter((c) => c.snippets.length > 0)
            .map((collection) => (
              <SnippetGroup
                title={<Link to={"/" + collection.path}>{collection.name}/</Link>}
                snippets={collection.snippets}
                key={collection.path}
              />
            ))}
        </StyledResults>
      </StyledResultsLayout>
    </Layout>
  );
}

export interface UserScreenProps extends BaseScreenProps {
  username?: string;
}

function useFilterableUserSnippets(userId: string) {
  let userData = useUserSnippets(userId);
  let { filter, updateFilter } = useSnippetFilter();

  let filteredCollections = useMemo(() => {
    if (!userData.collections.length) return [];

    let filteredCollections = filterCollections(filter, userData.collections);
    return orderBy(filteredCollections, "name");
  }, [userData, filter]);

  return {
    ...userData,
    filter,
    updateFilter,
    collections: filteredCollections,
  };
}
