import { useQuery } from "react-apollo-hooks";
import cache, { CacheOptions } from "../../utils/cache";
import { useState, useEffect } from "react";
import { isEqual } from "apollo-utilities";

export default function useApolloQuery(query, options = {}) {
  const { data, error, loading } = useQuery(query, options);
  return { data, error, loading };
}

// export function useApolloQueryWithCache(query, options, cacheOptions: CacheOptions) {
//   if (!cacheOptions || !cacheOptions.key) throw new Error("You need to pass cache options");
//   let cachedData = cache.get(cacheOptions.key);
//   const { data, error, loading } = useQuery(query, options);
//   let [stateData, setstateData] = useState(() => data || cachedData);

//   useEffect(() => {
//     console.log(
//       "TCL: useApolloQueryWithCache -> updating Data!",
//       data,
//       cache.get(cacheOptions.key),
//       loading
//     );
//     if (!loading && !isEqual({}, data) && !isEqual(data, cache.get(cacheOptions.key))) {
//       cache.set(cacheOptions.key, data);
//       setstateData(data);
//     }
//   }, [data, loading]);

//   return {
//     loading: !cachedData && loading,
//     data: stateData,
//     error,
//   };
// }
