import React from "react";
import Layout, { BaseScreenProps, StyledResultsLayout, StyledResults } from "./Layout";
import useApolloQuery from "../hooks/apollo/useApolloQuery";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { useCurrentUserContext } from "../hooks/useCurrentUser";
import useSnippetFilter from "../hooks/useSnippetFilter";
import { filterSnippets, getRefiners } from "../data/snippetsData";
import FilterPanel from "../components/snippets/FilterPanel";
import SnippetGroup from "../components/snippets/SnippetGroup";
import useApolloQueryWithCache from "../hooks/apollo/useApolloQueryWithCache";

export default function LanguageScreen({ language }: LanguageScreenProps) {
  let { mySnippets, otherSnippets, filter, updateFilter, refiners } = useLanguage(language);
  return (
    <Layout>
      <StyledResultsLayout>
        <FilterPanel
          filter={filter}
          updateFilter={updateFilter}
          excludes={["languages", "collections"]}
          refiners={refiners}
        />
        <StyledResults>
          {mySnippets.length > 0 && <SnippetGroup title="Mine" snippets={mySnippets} />}
          {otherSnippets.length > 0 && <SnippetGroup title="Community" snippets={otherSnippets} />}
        </StyledResults>
      </StyledResultsLayout>
    </Layout>
  );
}

export interface LanguageScreenProps extends BaseScreenProps {
  language?: string;
}

const useLanguage = function(language: string) {
  let { user } = useCurrentUserContext();
  let data = useApolloQueryWithCache(
    { query: LANGUAGE_SNIPPETS_QUERY, variables: { language } },
    "language-" + language,
    200
  );
  let { filter, updateFilter } = useSnippetFilter();
  let allSnippets = data && data.language ? data.language.snippets : [];
  let username = user ? user.username : null;

  let mySnippets = filterSnippets(
    filter,
    allSnippets.filter((s) => s.createdBy.username === username)
  );
  let otherSnippets = filterSnippets(
    filter,
    allSnippets.filter((s) => s.createdBy.username !== username)
  );

  return {
    mySnippets,
    otherSnippets,
    filter,
    updateFilter,
    refiners: getRefiners(allSnippets),
  };
};

const LANGUAGE_SNIPPETS_QUERY = gql`
  query GetSnippetsByLanguage($language: String!) {
    language(key: $language) {
      snippets {
        title
        key
        filename
        path
        language {
          name
          key
        }
        description
        tags {
          key
        }
        createdBy {
          key
          username
          profilePhoto
        }
      }
    }
  }
`;
