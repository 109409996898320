import { useReducer } from "react";
import { SnippetFilter } from "../data/interfaces";

export default function useSnippetFilter() {
  let [state, dispatch] = useReducer(reduceFilterableSnippets, {
    filter: {},
  } as any);
  let filter = state.filter;
  return {
    filter: filter as SnippetFilter,
    updateFilter: (key: string, value: any) => dispatch({ type: "update-filter", key, value }),
  };
}

function reduceFilterableSnippets(state, action) {
  let handlers = {
    "update-filter": () => {
      // nothing changed so just send back same object
      if (state.filter[action.key] === action.value) return state;
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value,
        },
      };
    },
  };
  return handlers[action.type] ? handlers[action.type]() : state;
}
