import React from "react";
import CommandBar from "../components/pageHeader/CommandBar";
import styled from "@emotion/styled";

function Layout(props: AppScreenProps) {
  let {
    title = "",
    subtitle = "",
    children,
    className = "",
    commands: actions,
    hideCommandBar = false,
  } = props;

  return (
    <StyledScreen className={"screen " + className} hideCommandBar={hideCommandBar}>
      {hideCommandBar !== true && <CommandBar actions={actions} />}
      <div className="content">
        {title && (
          <div className="page-title-area">
            <h1 className="page-title">{title}</h1>
            {subtitle && <ScreenSubtitle subtitle={subtitle} />}
          </div>
        )}
        {children}
      </div>
    </StyledScreen>
  );
}

const ScreenSubtitle = ({ subtitle }) => {
  if (!subtitle) return null;
  if (typeof subtitle === "function") return subtitle();
  return <div className="page-subtitle">{subtitle}</div>;
};

export default React.memo(Layout);

export interface AppScreenProps {
  /** Screen title */
  title?: string;
  /** Screen subtitle, can be a string or render function */
  subtitle?: () => JSX.Element | string;
  /** decorate the screen with an extra class */
  className?: string;
  commands?: JSX.Element;
  hideCommandBar?: boolean;
  /** Screen's main content */
  children: JSX.Element;
}

export interface BaseScreenProps {
  path?: string;
  location?: Location;
  default?: boolean;
}

const StyledScreen = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  .page-title-area {
    padding: 20px 10px;
  }
  .content {
    position: absolute;
    top: ${(props) => (props.hideCommandBar ? "0" : "40px")};
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const StyledResultsLayout = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  box-sizing: border-box;
  height: 100%;
`;

export const StyledResults = styled.div`
  position: relative;
  background: var(--black-transparent);
  border-top: 1px solid #555;
  padding: 10px 15px;
`;
