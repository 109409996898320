import React, { useCallback, useState, useEffect, useRef } from "react";
import { CodeSnippet, SaveSnippetInput, SnippetLanguage } from "../../data/interfaces";
import gql from "graphql-tag";
import Textbox from "../inputs/Textbox";
import LanguagePicker from "../inputs/LanguagePicker";
import { RightPanel } from "../primitives/Container";
import CollectionPicker from "../inputs/CollectionPicker";
import TagPicker from "../inputs/TagPicker";
import { useCurrentUserContext } from "../../hooks/useCurrentUser";
import useUserSnippets from "../../hooks/useUserSnippets";
import useApolloQueryWithCache from "../../hooks/apollo/useApolloQueryWithCache";
import { getRefiners, getFilenameFromTitle } from "../../data/snippetsData";
import { kebabCase } from "lodash";
import SnippetInfoProperty from "./SnippetInfoProperty";
export default function SnippetForm({
  onPropertyChange,
  snippet,
  disabled = false,
}: SnippetFormProps) {
  let { refiners } = useSnippetFormData();
  let { user = {} } = useCurrentUserContext();
  let isFilenameDirtyRef = useRef(!!(snippet && snippet.filename));
  if (!snippet) return null;

  const filename =
    isFilenameDirtyRef.current && snippet.filename
      ? snippet.filename
      : getFilenameFromTitle(snippet, refiners.languages);

  return (
    <RightPanel>
      <form>
        <Textbox
          label="Title"
          id="title"
          value={snippet.title}
          onChange={onPropertyChange}
          required={true}
          disabled={disabled}
          autofocus={true}
        />

        <LanguagePicker
          required={true}
          value={snippet.language}
          onChange={(selected: string) => onPropertyChange("language", selected)}
          creatable={true}
          options={refiners.languages}
        />
        <TagPicker
          values={snippet.tags}
          onChange={(tags: string[]) => onPropertyChange("tags", tags)}
          creatable={true}
          options={refiners.tags}
        />
        <Textbox
          label="Description"
          id="description"
          value={snippet.description}
          disabled={disabled}
          onChange={onPropertyChange}
          multiline={true}
        />

        <CollectionPicker
          required={true}
          value={snippet.collection}
          onChange={(collection) => onPropertyChange("collection", collection)}
          creatable={true}
          options={refiners.collections}
        />
        <Textbox
          label="Filename"
          id="filename"
          value={filename}
          onChange={(id, value) => {
            isFilenameDirtyRef.current = true;
            return onPropertyChange(id, value);
          }}
          disabled={disabled}
        />
        {!snippet.key && (
          <SnippetInfoProperty label="Path">
            <pre>{user.key + "/" + snippet.collection + "/" + filename}</pre>
          </SnippetInfoProperty>
        )}
      </form>
    </RightPanel>
  );
}

export interface SnippetFormProps {
  snippet: SaveSnippetInput;
  onPropertyChange: (id: string, value: any) => void;
  disabled?: boolean;
}

function useSnippetFormData() {
  let data = useApolloQueryWithCache({ query: SNIPPET_FORM_QUERY }, "snippet-form", 10);
  let refiners: any = {};
  if (data && data.me) {
    refiners = getRefiners(data.me.snippets);
    refiners.languages = data.languages.map((l) => ({
      value: l.key,
      label: l.name,
      extensions: l.extensions,
    }));
  }
  return { refiners };
}

const SNIPPET_FORM_QUERY = gql`
  query GetLanguagesAndMyTagsAndCollections {
    me {
      snippets {
        collection
        tags {
          key
        }
      }
    }
    languages {
      key
      name
      extensions
    }
  }
`;
