import useCurrentUser, { useCurrentUserContext } from "../hooks/useCurrentUser";
import { SaveSnippetResult } from "../data/interfaces";
import { navigate } from "@reach/router";
import useSnippetForm from "./useSnippetForm";
import { useSaveSnippet, useFetchSnippet, useRemoveSnippet } from "./useSnippetApi";

export default function useSnippetDetails(snippetPath) {
  let { snippet, error, loading } = useFetchSnippet(snippetPath, true);

  let { user: currentUser } = useCurrentUserContext();

  let canEdit =
    snippet && currentUser && snippet.createdBy.key === currentUser.username.toLowerCase();
  let { isDirty, onPropertyChange, modifiedSnippet, discardChanges, isValid } = useSnippetForm(
    snippet
  );

  let _saveToDb = useSaveSnippet(modifiedSnippet);
  let _removeFromDb = useRemoveSnippet(snippet ? snippet.key : "");

  let onRemove = async () => {
    console.log("removing", canEdit);
    if (!canEdit) return;
    if (window.confirm("Are you sure?")) {
      let { error } = await _removeFromDb();
      if (!error) {
        navigate("/" + currentUser.username.toLowerCase());
      }
    }
  };

  let onSave = async () => {
    if (!canEdit || !isDirty) return;

    // call graphql mutation?
    console.log("Saving Snippet", modifiedSnippet);
    let { data, error } = await _saveToDb();
    if (data) {
      let result = data.saveSnippet as SaveSnippetResult;
      if (snippet.path !== result.path) {
        console.log("navigating to new path");
        navigate("/" + result.path);
      }
    }
  };

  return {
    snippet,
    error,
    loading,
    canEdit,
    onPropertyChange,
    isDirty,
    onSave,
    onRemove,
    discardChanges,
    snippetInput: modifiedSnippet,
    isValid,
  };
}
