import useApolloQuery from "./apollo/useApolloQuery";
import gql from "graphql-tag";
import { useMemo, useEffect } from "react";
import { CodeSnippet } from "../data/interfaces";
import useApolloQueryWithCache from "./apollo/useApolloQueryWithCache";
import { getCacheKeysByPrefix, clearCacheByPrefix } from "../utils/cache";
import { getRefiners } from "../data/snippetsData";

export default function useUserSnippets(userId: string) {
  let apolloQuery = { query: USER_SNIPPETS_QUERY, variables: { userId } };
  let data = useApolloQueryWithCache(apolloQuery, "usersnippets-" + userId, 250);

  useEffect(() => {
    let cachedItems = getCacheKeysByPrefix("code-snippets-usersnippets-", localStorage);
    if (cachedItems.length > 10) {
      clearCacheByPrefix("code-snippets-usersnippets-", localStorage);
    }
  });

  let memoizedData = useMemo(() => {
    let user = data ? data.user : null;
    let snippets: CodeSnippet[] = data && data.user && data.user.snippets ? data.user.snippets : [];
    let collectionsObj = snippets.reduce((obj, snippet) => {
      if (!obj[snippet.collection]) {
        obj[snippet.collection] = {
          key: snippet.collection,
          name: snippet.collection,
          snippets: [],
          path: snippet.path.replace("/" + snippet.filename, ""),
        };
      }
      obj[snippet.collection].snippets.push(snippet);
      return obj;
    }, {});

    return {
      collections: Object.values(collectionsObj),
      user,
      refiners: getRefiners(snippets),
      loading: !user,
    };
  }, [data]);

  return memoizedData;
}

const USER_SNIPPETS_QUERY = gql`
  query GetUserSnippetsByCollection($userId: String!) {
    user(key: $userId) {
      name
      username
      snippets {
        collection
        title
        key
        filename
        path
        language {
          name
          key
        }
        description
        tags {
          key
        }
      }
    }
  }
`;
