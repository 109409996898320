import React from "react";
import { Router } from "@reach/router";
import HomeScreen from "./HomeScreen";
import UserScreen from "./UserScreen";
import TokenScreen from "./TokenScreen";
import NewSnippetScreen from "./NewSnippet";
import SnippetDetailsScreen from "./SnippetDetailsScreen";
import HowToScreen from "./HowToScreen";
import CollectionScreen from "./CollectionScreen";
import ExploreScreen from "./ExploreSceen";
import Nav from "../components/pageHeader/Nav";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import { ThemeProvider } from "emotion-theming";

import "./App.css";
import { transparentize, darken } from "polished";
import styled from "@emotion/styled";
import TagScreen from "./TagScreen";
import LanguageScreen from "./LanguageScreen";
import useCurrentUser, { CurrentUserContext } from "../hooks/useCurrentUser";
import DocsScreen from "./DocsScreen";
import useLogRocket from "../hooks/useLogRocket";

function App({}) {
  let currentUserData = useCurrentUser();
  useLogRocket(currentUserData.user);
  useGoogleAnalytics(currentUserData.user ? currentUserData.user.key : "");
  return (
    <ThemeProvider theme={theme}>
      <CurrentUserContext.Provider value={currentUserData}>
        <StyledApp className="app">
          <Nav />
          <React.Suspense fallback={<div>Loading...</div>}>
            <Router>
              <HomeScreen path="" default />
              <NewSnippetScreen path="/new-snippet" />
              <UserScreen path="/:username" />
              <TagScreen path="/explore/tags/:tag" />
              <LanguageScreen path="/explore/languages/:language" />
              <CollectionScreen path="/:username/:collectionName" />
              <SnippetDetailsScreen path="/:username/:collectionName/:snippetName" />
              <HowToScreen path="/how-to" />
              <DocsScreen path="/docs" />
              <DocsScreen path="/docs/:docsPath" />
              <ExploreScreen path="/explore" />
              <ExploreScreen path="/explore/tags" />
              <ExploreScreen path="/explore/languages" />
              <TokenScreen path="/token" />
            </Router>
          </React.Suspense>
        </StyledApp>
      </CurrentUserContext.Provider>
    </ThemeProvider>
  );
}

const theme = {
  black: "#161616",
  blackBackground: transparentize(0.5, "#161616"),
  dark: "#3a4e48",
  darkBackground: transparentize(0.5, "#3a4e48"),
  white: "#eee",
  light: "#ccc",
  primary: "#ff6f59",
  secondary: "#43aa8b",
  red: "#b6afae",
};

const StyledApp = styled.div`
  min-height: 100%;
  position: relative;
  /* display: flex;
  flex-direction: column; */
  font-family: var(--sans-serif);

  & > *:nth-child(2) {
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: Inconsolata, "Courier New", Courier, monospace;
    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */
    font-weight: lighter;
    margin: 5px 0;
  }

  input {
    font-family: Montserrat, sans-serif;
  }

  label {
    font-family: var(--monospace);
    text-transform: uppercase;
    color: ${(props) => darken(0.2, props.theme.light)};
    font-size: 0.8rem;
    margin-bottom: 4px;
    &.required::after {
      content: "*";
      color: var(--primary-light);
      position: relative;
      top: -4px;
      left: 3px;
      font-size: 0.6rem;
    }
  }
`;
export default App;
