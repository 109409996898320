import React from "react";
import Layout, { BaseScreenProps } from "./Layout";
import { getAccessToken, redirectToLogin } from "../auth/auth";
import styled from "@emotion/styled";
import Textbox from "../components/inputs/Textbox";
import { useCurrentUserContext } from "../hooks/useCurrentUser";
import { BigPrimaryButton } from "../components/primitives/Buttons";

const checkSupportsEasyClipboard = () => {
  return window.navigator && window.navigator.clipboard;
};
const copyTokenHeader = async () => {
  await navigator.clipboard.writeText(`"Authorization": "Bearer ${getAccessToken()}"`);
};

const copyToken = async () => {
  await navigator.clipboard.writeText(getAccessToken());
};

function AccessTokenScreen({ location }: BaseScreenProps) {
  let { user, isLoggedIn } = useCurrentUserContext();
  return (
    <Layout>
      <StyledContainer>
        {isLoggedIn && (
          <React.Fragment>
            <Textbox
              label="Access Token"
              id="description"
              value={getAccessToken()}
              disabled={true}
              onChange={() => {}}
              multiline={true}
            />
            {checkSupportsEasyClipboard() && (
              <>
                <button onClick={copyToken}>Copy Token</button>
                <button onClick={copyTokenHeader}>Copy Auth Header</button>
              </>
            )}
          </React.Fragment>
        )}

        {!isLoggedIn && (
          <div>
            <h3>Please login to see your access token</h3>
            <BigPrimaryButton onClick={() => redirectToLogin()}>Login w/ Github</BigPrimaryButton>
          </div>
        )}
      </StyledContainer>
    </Layout>
  );
}

const StyledContainer = styled.div`
  padding: 10px;
  textarea {
    width: 600px;
    max-width: 100%;
  }
`;
export default AccessTokenScreen;
